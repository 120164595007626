<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Forum : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status forum -->
          <b-form-group
            label="Status Tampilkan Forum"
            label-for="status_tampilkan_forum"
          >
            <validation-provider
              #default="{ errors }"
              name="status_tampilkan_forum"
            >
              <v-select
                v-model="filter.forum_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusForumList"
                placeholder="Status Tampilkan Forum"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status admin -->
          <b-form-group label="Status Admin" label-for="status_admin">
            <validation-provider
              #default="{ errors }"
              name="status_tampilkan_forum"
            >
              <v-select
                v-model="filter.forum_status_byadmin"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusAdminList"
                placeholder="Status Dari Admin"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="2" xl="2" class="mb-1">
          <!-- status admin -->
          <b-form-group label="Nama Anggota" label-for="nama_anggota">
            <validation-provider
              #default="{ errors }"
              name="orang_nama_lengkap"
            >
              <b-form-input
                v-model="filter.orang_nama_lengkap"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Nama Anggota"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="reloadPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalDetailStudiKasus(data.item)">
            <feather-icon icon="InfoIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>
          <b-dropdown-item @click="lihatKomentar(data.item)">
            <feather-icon icon="MessageCircleIcon" size="16" />
            <span class="align-middle ml-50">Lihat Komentar</span>
          </b-dropdown-item>
          <b-dropdown-item @click="lihatAduan(data.item)">
            <feather-icon icon="ArchiveIcon" size="16" />
            <span class="align-middle ml-50">Lihat Aduan</span>
          </b-dropdown-item>
          <b-dropdown-item @click="active(data.item)">
            <feather-icon icon="CheckIcon" size="16" />
            <span class="align-middle ml-50">Aktifkan</span>
          </b-dropdown-item>
          <b-dropdown-item @click="final(data.item)">
            <feather-icon icon="ChevronsUpIcon" size="16" />
            <span class="align-middle ml-50">Final</span>
          </b-dropdown-item>
          <b-dropdown-item @click="inactive(data.item)">
            <feather-icon icon="DeleteIcon" size="16" />
            <span class="align-middle ml-50">Inactive</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <!-- // eslint-disable-next-line-->

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <!-- modal detail forum-->
    <b-modal id="modal-detail-studi-kasus" size="xl" hide-footer>
      <template #modal-title> Detail Forum </template>
      <!-- Invoice Description: Total -->
      <table style="width: 100%">
        <tr>
          <td><b>Forum Konten :</b></td>
        </tr>
        <br />
        <quill-editor
          v-model="updateStudiKasus.forum_content"
          :options="snowOption"
        />
      </table>
    </b-modal>

    <!-- modal list lihat komentar -->
    <b-modal
      id="modal-lihat-komentar"
      size="xl"
      title="lihat komentar"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <p>
        <b> Total Komentar : {{ komentarRows }}</b>
      </p>
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="komentars"
        :fields="list_komentars"
        responsive
        class="mb-0"
        @row-selected="onRowSelected"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>
            <b-dropdown-item @click="deleteComment(data.item)">
              <feather-icon icon="DeleteIcon" size="16" />
              <span class="align-middle ml-50">Delete Komentar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="lihatKomentarCurrentPage"
            hide-goto-end-buttons
            :total-rows="lihatKomentarRows"
            :per-page="lihatKomentarPerPage"
          />
        </div>
      </b-card-body>
    </b-modal>

    <!-- modal list aduan-->
    <b-modal
      id="modal-lihat-aduan"
      size="xl"
      title="List Peserta"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <p>
        <b> Total Aduan : {{ aduanRows }}</b>
      </p>
      <b-table
        ref="selectableTable"
        selectable
        :select-mode="selectMode"
        :items="aduans"
        :fields="list_aduans"
        responsive
        class="mb-0"
        @row-selected="onRowSelected"
      >
        <template #cell(document)="data">
          <a
            :href="data.item.document"
            v-if="data.item.document"
            target="_blank"
            >Open</a
          >
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div></div>
        <!--pagination -->
        <div class="demo-inline-spacing pt-0">
          <b-pagination
            v-model="lihatAduanCurrentPage"
            hide-goto-end-buttons
            :total-rows="lihatAduanRows"
            :per-page="lihatAduanPerPage"
          />
        </div>
      </b-card-body>
    </b-modal>
  </b-card-code>
</template>

<script>
/* eslint-disable */
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormTimepicker,
  BFormSpinbutton,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import API from "@/api";

/* eslint-disable */
export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    ValidationProvider,
    ValidationObserver,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BFormTimepicker,
    BFormSpinbutton,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  /* eslint-disable */
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,

      // lihat lihat Komentar
      lihatKomentarCurrentPage: 1,
      lihatKomentarRows: 0,
      lihatKomentarPerPage: 20,

      //lihat aduan
      lihatAduanCurrentPage: 1,
      lihatAduanRows: 0,
      lihatAduanPerPage: 20,

      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        [{ key: "orang.orang_nama_lengkap", label: "Anggota" }],
        [{ key: "forum_status", label: "Status Buat" }],
        [{ key: "forum_status_byadmin", label: "Status dari Admin" }],
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */

      //lihat komentar
      list_komentars: [
        { key: "id", label: "Id" },
        [{ key: "orang.orang_nama_lengkap", label: "Anggota" }],
        "forcomment_content",
        "forum_id",
        "action",
      ],
      komentars: [],

      //lihat aduan
      list_aduans: [
        { key: "id", label: "Id" },
        [{ key: "orang.orang_nama_lengkap", label: "Anggota" }],
        "foreport_content",
        "forum_id",
      ],
      aduans: [],

      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],
      search: null,
      updateStudiKasus: {},
      formatted: "",
      selected: "",
      snowOption: {
        theme: "snow",
      },
      filter: {
        forum_status: null,
        forum_status_byadmin: null,
        orang_nama_lengkap: null,
      },
      statusForumList: ["final", "draft"],
      statusAdminList: ["active", "not-active"],
    };
  },
  /* eslint-disable */

  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getStudiKasus();
      } else {
        this.currentPage = 1;
      }
    },
    refreshKomentar() {
      if (this.lihatKomentarCurrentPage == 1) {
        this.lihat_komentar();
      } else {
        this.lihatKomentarCurrentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },

    modalDetailStudiKasus(item) {
      this.updateStudiKasus = item;
      this.$bvModal.show("modal-detail-studi-kasus");
    },

    lihatKomentar(item) {
      this.lihat_komentar(item);
      this.$bvModal.show("modal-lihat-komentar");
    },

    lihatAduan(item) {
      this.lihat_aduan(item);
      this.$bvModal.show("modal-lihat-aduan");
    },

    reloadPage() {
      location.reload();
    },

    async getStudiKasus() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.studi_kasus.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data forum");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async lihat_komentar(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var idKomentar = ids.id;
        const queryData = {
          limit: this.lihatKomentarPerPage,
          page: this.lihatKomentarCurrentPage,
          search: this.search,
        };
        const { data } = await API.studi_kasus.lihatKomentar(
          idKomentar,
          queryData
        );
        this.data_table = data;
        this.komentars = data.data;
        this.komentarRows = data.total;
        this.handleMsgSuccess("Berhasil tampilkan data lihat komentar");
        this.refreshKomentar();
      } catch (error) {
        this.handleMsgErrors(error, "gagal lihat komentar");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async lihat_aduan(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var idAduan = ids.id;
        const queryData = {
          limit: this.lihatAduanPerPage,
          page: this.lihatAduanCurrentPage,
          search: this.search,
        };
        const { data } = await API.studi_kasus.lihatReport(idAduan, queryData);
        this.data_table = data;
        this.aduans = data.data;
        this.aduanRows = data.total;
        this.handleMsgSuccess("Berhasil tampilkan data lihat aduan");
      } catch (error) {
        this.handleMsgErrors(error, "gagal lihat aduan");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async deleteComment(id) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id_studi_kasus = id.forum_id;
        var id_komen = id.id;
        const { data } = await API.studi_kasus.deleteComment(
          id_studi_kasus,
          id_komen
        );
        this.handleMsgSuccess("Berhasil delete komentar");
        this.refreshKomentar();
      } catch (error) {
        this.handleMsgErrors(error, "gagal delete komentar");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async active(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = ids.id;
        const { data } = await API.studi_kasus.active(id);
        this.handleMsgSuccess("Berhasil Active");
        this.refreshPage();
      } catch (error) {
        this.handleMsgErrors(error, "gagal active");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async final(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = ids.id;
        const { data } = await API.studi_kasus.final(id);
        this.handleMsgSuccess("Berhasil ubah status menjadi final");
        this.refreshPage();
      } catch (error) {
        this.handleMsgErrors(error, "gagal active");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async inactive(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = ids.id;
        const { data } = await API.studi_kasus.nonActive(id);
        this.handleMsgSuccess("Berhasil Inactive");
        this.refreshPage();
      } catch (error) {
        this.handleMsgErrors(error, "gagal inactive");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  /* eslint-disable */
  mounted() {
    this.getStudiKasus();
    this.lihat_komentar();
  },
  /* eslint-disable */
  watch: {
    currentPage() {
      this.getStudiKasus();
      this.lihat_komentar();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
